import { useStyles } from './MilltimeLogin.styles';
import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useMilltimeContext, MilltimeAuthResponse } from '../../Providers/MilltimeProvider';
import { Field } from '../Shared/Field/Field';

const MilltimeLogin = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [validForm, setValidForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorStatus, setErrorStatus] = useState<MilltimeAuthResponse | null>(null);
    const milltimeContext = useMilltimeContext();
    const formRef = useRef<HTMLFormElement>(null);
    const classes = useStyles();

    useEffect(() => {
        if (formRef.current !== null) {
            setValidForm(formRef.current.checkValidity());
        } else {
            setValidForm(false);
        }
    }, [username, password]);

    const onFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const result = await milltimeContext.signIn(username, password);
        setLoading(false);

        result === MilltimeAuthResponse.Success ? setErrorStatus(null) : setErrorStatus(result);
    };

    const onInputchange = (value: string, changeFunction: React.Dispatch<React.SetStateAction<string>>) => {
        changeFunction(value);
    };

    const getText = () => {
        if (errorStatus === MilltimeAuthResponse.IncorrectCredentials) {
            return 'Incorrect username or password. Please try again.';
        } else if (errorStatus === MilltimeAuthResponse.ServerError) {
            return 'Something went wrong when communicating with Milltime. Please try again later.';
        } else if (errorStatus === MilltimeAuthResponse.UnknownError) {
            return 'Unknown error...';
        }

        return '';
    };

    return (
        <main>
            <Grid container justify="center">
                <Grid className={classes.formContainer} item xl={3} lg={5} md={6} sm={8} xs={12}>
                    <form className={classes.loginForm} onSubmit={onFormSubmit} ref={formRef}>
                        <Typography className={classes.title} variant="h1">
                            Sign in
                        </Typography>
                        <Typography className={classes.subtitle} variant="h4" component="h2">
                            Please fill in your Milltime credentials.
                        </Typography>
                        <Field
                            id="milltime-username"
                            label="Milltime username"
                            required
                            type="text"
                            value={username}
                            onChange={(event: any) => onInputchange(event.target.value, setUsername)}
                            inForm
                        />
                        <Field
                            id="milltime-password"
                            label="Milltime password"
                            required
                            type="password"
                            value={password}
                            fullWidth
                            errorText={getText()}
                            onChange={(event: any) => onInputchange(event.target.value, setPassword)}
                            inForm
                        />

                        <div>
                            <Button
                                className={classes.loginButton}
                                type="submit"
                                variant="contained"
                                disabled={!validForm || loading}
                                color="primary"
                            >
                                {loading ? 'Loading...' : 'Log In'}
                            </Button>
                        </div>
                    </form>
                </Grid>
            </Grid>
        </main>
    );
};

export default MilltimeLogin;
