import React from 'react';
import ReactDatePicker from '../../../../Shared/ReactDatepicker/DatePicker';
import { useStyles } from './DatePickers.styles';
interface IProps {
    fromDate: Date | null;
    toDate: Date | null;

    setFromDate: (date: Date | null) => void;
    setToDate: (date: Date | null) => void;
}

const DatePickers = (props: IProps) => {
    // TODO (daol):
    // From date should be decided based on last sync, which should be retrieved from localStorage.
    // If no date of last sync exists, pick first day of current week.

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.from}>
                <ReactDatePicker
                    selectsStart
                    selected={props.fromDate}
                    startDate={props.fromDate}
                    endDate={props.toDate}
                    onChange={props.setFromDate}
                    placeholderText="From"
                />
            </div>
            <ReactDatePicker
                selectsEnd
                selected={props.toDate}
                startDate={props.fromDate}
                endDate={props.toDate}
                onChange={props.setToDate}
                placeholderText="To"
            />
            {/* Use from & to date */}
            {/* <KeyboardDatePicker
                value={props.fromDate}
                onChange={props.setFromDate}
                margin="normal"
                variant="inline"
                format="yyyy-MM-dd"
                label="From"
            />
            <KeyboardDatePicker
                value={props.toDate}
                onChange={props.setToDate}
                margin="normal"
                variant="inline"
                format="yyyy-MM-dd"
                label="To"
            /> */}
        </div>
    );
};

export default DatePickers;
