import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Container } from '../../Shared/Container/Container';
import { Row } from './Row/Row';
import { ActionBarContainer } from '../../Shared/ActionBarContainer/ActionBarContainer';
import { useStyles } from './ProjectColors.styles';

const ColorPicker = (props: RouteComponentProps) => {
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        <div>
            <ActionBarContainer>
                <Button variant="outlined" onClick={() => navigate('/')}>
                    Back
                </Button>
            </ActionBarContainer>
            <Container>
                <Typography variant="h1" className={classes.title}>
                    Project colors
                </Typography>
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
                <Row project="TTM" color="#000" />
            </Container>
        </div>
    );
};

export default ColorPicker;
