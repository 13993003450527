import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100vh',
            height: '100%',
            backgroundColor: theme.palette.background.default,
        },
    }),
);
