import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
        },
        from: {
            marginRight: '1rem',
        },
    }),
);
