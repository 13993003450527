import React, { useState } from 'react';
import RDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ArrowForward from '@material-ui/icons/KeyboardArrowRightRounded';
import ArrowBack from '@material-ui/icons/KeyboardArrowLeftRounded';
import { Typography, IconButton } from '@material-ui/core';
import sv from 'date-fns/locale/sv';
import DateInput from './DateInput/DateInput';
import { useStyles } from './Datepicker.styles';

interface IDatepickerProps extends ReactDatePickerProps {
    label?: string;
}

const ReactDatePicker = (props: IDatepickerProps) => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());

    const classes = useStyles();
    const { label, ...rest } = props;

    const getMonthByNumber = (month: number) => {
        return months[month];
    };

    const months = [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
    ];

    const ref = React.createRef<string>();

    return (
        <RDatePicker
            calendarClassName={classes.calendar}
            selected={startDate}
            showWeekNumbers
            locale={sv}
            dateFormat="yyyy-MM-dd"
            onChange={(date) => setStartDate(date)}
            customInput={
                <DateInput
                    ref={ref}
                    value={startDate}
                    label={label}
                    inputProps={{ 'aria-label': !label ? rest.placeholderText : '' }}
                    placeholder={rest.placeholderText}
                />
            }
            customInputRef={ref.current || undefined}
            renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div className={classes.header}>
                    <IconButton
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        aria-label="Gå till nästa månad"
                        size="small"
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" component="span">
                        {getMonthByNumber(date.getMonth())} {date.getFullYear()}
                    </Typography>
                    <IconButton
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        aria-label="Gå till föregående månad"
                        size="small"
                    >
                        <ArrowForward />
                    </IconButton>
                </div>
            )}
            {...rest}
        />
    );
};

export default ReactDatePicker;
