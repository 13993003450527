import React, { useState } from 'react';
import { useStyles } from './Field.styles';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { Tooltip, Typography, FormControl, InputBase } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import { InputLabel } from '../InputLabel/InputLabel';

export interface IFieldProps extends InputBaseProps {
    isDisabled?: boolean;
    label?: string;
    inForm?: boolean;
    errorText?: string;
    helperText?: string;
    helperTextAsTooltip?: boolean;
}

export const Field = (props: IFieldProps) => {
    const classes = useStyles();
    const { isDisabled, helperTextAsTooltip, label, helperText, errorText, inForm, ...rest } = props;
    const [isFocused, setIsFocused] = useState(false);

    function getTooltip() {
        return errorText ? errorText : helperText;
    }

    const labelWithoutSpaces = props.label?.replace(/ /g, '');
    const randId: string = `${labelWithoutSpaces}-${Math.floor(Math.random() * 1000)}`;

    return (
        <FormControl
            error={props.error}
            className={`${classes.formControl} ${rest.error && !isFocused ? classes.error : ''} ${
                inForm ? classes.inForm : ''
            }`}
        >
            <div className={classes.title}>
                {!props.readOnly && (helperText || errorText) && (
                    <Tooltip title={getTooltip() ?? ''}>
                        <ErrorIcon fontSize="small" className={classes.icon} />
                    </Tooltip>
                )}
                {props.label && <InputLabel label={`${props.label} ${props.required ? '*' : ''}`} id={randId} />}
            </div>

            <InputBase
                {...rest}
                disabled={isDisabled}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                inputProps={{ 'aria-labelledby': randId }}
            />
            {helperTextAsTooltip && <span className="sr-only">{getTooltip()}</span>}
            {!helperTextAsTooltip && (
                <>
                    {helperText && !errorText && (
                        <Typography variant="caption" className={classes.helpText}>
                            {helperText}
                        </Typography>
                    )}

                    {errorText && (
                        <Typography color="error" variant="caption" className={classes.helpText}>
                            {errorText}
                        </Typography>
                    )}
                </>
            )}
        </FormControl>
    );
};

export default Field;
