export const dark = {
    primary: {
        light: '#45B4FF',
        main: '#3498db',
        dark: '#2B80B9',
        contrastText: '#fff',
    },
    secondary: {
        light: '#1C1C1C',
        main: '#161616',
        dark: '#7B005E',
        contrastText: '#fff',
    },
    grey: {
        900: '#f5f5f5',
        800: '#eeeeee',
        700: '#e0e0e0',
        600: '#e8e8e8',
        500: '#9e9e9e',
        400: '#757575',
        300: '#2C2C2C',
        200: '#424242',
        100: '#212121',
    },
    background: {
        default: '#151515',
        paper: '#fff',
    },
    text: {
        primary: '#ffffff',
    },
    error: {
        main: '#DC3030',
        light: '#E36463',
    },
    divider: '#0F0F0F',
};
