import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, Event } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { getDate, getMonth, getYear, set, setSeconds } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import useTimeEntryApi from '../../../../../../Api/TimeEntryApi';
import { INewTimeEntry, ITimeEntryTime } from '../../../../../../Models/Milltime';
import { useMilltimeContext } from '../../../../../../Providers/MilltimeProvider';
import { minutesToTotalHoursAndMinutes } from '../../../../../../Utils/TimeHelpers';
import Field from '../../../../../Shared/Field/Field';
import Timepicker2 from '../../../../../Shared/Timepicker/Timepicker2';
import TaskPicker from '../../../TaskPicker/TaskPicker';
import { StartFromPreviousEntry } from '../../StartFromPreviousEntry';
import { useStyles } from './TimeEntryTime.styles';

interface IProps {
    time: ITimeEntryTime;
    hideLabels?: boolean;
    displayStartFromEntryButton?: boolean;
    fetchTimeEntries: () => Promise<void>;
}

const TimeEntryTime: FC<IProps> = ({ time, fetchTimeEntries, hideLabels, displayStartFromEntryButton }) => {
    const { projects, getAllDescriptionEntries } = useMilltimeContext();
    const { deleteTimeEntry, updateTimeEntry } = useTimeEntryApi();
    const classes = useStyles();

    const project = projects.find((x) => x.id === time.projectId);
    const activity = project?.activities.find((x) => x.id === time.activityId);

    const updateEntryStartDate = async (newDate: Date | null) => {
        if (newDate !== null) {
            const newDateInfo = { year: getYear(newDate), month: getMonth(newDate), date: getDate(newDate) };
            const startTime = set(new Date(time.startTime), newDateInfo);
            const stopTime = set(new Date(time.stopTime), newDateInfo);
            await updateEntry({ startTime, stopTime });
        }
    };

    const deleteEntryClick = async () => {
        await deleteTimeEntry(time.id);
        await fetchTimeEntries();
    };

    const updateEntry = useCallback(
        async (newEntry: Partial<INewTimeEntry>) => {
            const updatedObject: INewTimeEntry = {
                id: time.id,
                projectId: time.projectId,
                activityId: time.activityId,
                description: time.description,
                startTime: new Date(time.startTime),
                stopTime: time.stopTime ? new Date(time.stopTime) : null,
            };
            Object.assign(updatedObject, newEntry);

            await updateTimeEntry(updatedObject.id as number, updatedObject);
            await fetchTimeEntries();
            await getAllDescriptionEntries();
        },
        [time, updateTimeEntry, fetchTimeEntries, getAllDescriptionEntries],
    );

    return (
        <div className={`${classes.container} ${hideLabels ? classes.notGroupedMargin : ''}`}>
            <TaskPicker
                hideLabels={hideLabels}
                description={time.description}
                project={project || null}
                activity={activity || null}
                setProject={(value) => updateEntry({ projectId: value?.id ?? null, activityId: null })}
                setActivity={(value) => updateEntry({ activityId: value?.id ?? null })}
                setDescription={(value) => updateEntry({ description: value })}
                onSuggestionSelected={(project, activity, description) =>
                    updateEntry({
                        projectId: project?.id,
                        description: description,
                        activityId: activity?.id,
                    })
                }
            />
            <div className={classes.timeInputContainer}>
                <span className={classes.timepicker}>
                    <Timepicker2
                        label={hideLabels ? undefined : 'Start'}
                        value={time.startTime}
                        error={!time.isValid}
                        helperText={time.validationMessage}
                        onChange={(date: Date) => updateEntry({ startTime: setSeconds(date, 0) })}
                    />
                </span>
                <span className={classes.timepicker}>
                    <Timepicker2
                        label={hideLabels ? undefined : 'Stop'}
                        value={time.stopTime}
                        error={!time.isValid}
                        helperText={time.validationMessage}
                        onChange={(date) => updateEntry({ stopTime: setSeconds(date, 0) })}
                    />
                </span>

                <div className={classes.timeSummaryContainer}>
                    <Field
                        label={hideLabels ? undefined : 'Time'}
                        value={time.totalMinutes !== null ? minutesToTotalHoursAndMinutes(time.totalMinutes) : null}
                        readOnly
                    />
                </div>
                <div className={`${classes.buttonContainer} ${hideLabels ? '' : classes.groupedButtonContainer}`}>
                    <ButtonDatePicker date={new Date(time.startTime)} onDateChange={updateEntryStartDate} />
                    <IconButton onClick={deleteEntryClick} aria-label="Delete entry">
                        <Delete />
                    </IconButton>
                    {displayStartFromEntryButton && (
                        <StartFromPreviousEntry time={time} fetchEntries={fetchTimeEntries} />
                    )}
                </div>
            </div>
        </div>
    );
};

interface ButtonDatePickerProps {
    date: Date;
    onDateChange: (newDate: Date | null) => void;
}

const ButtonDatePicker: FC<ButtonDatePickerProps> = ({ date, onDateChange }) => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <Tooltip title="Update entry date">
                <IconButton onClick={() => setOpen(true)} aria-label="Update entry date">
                    <Event />
                </IconButton>
            </Tooltip>
            <DatePicker
                value={date}
                open={open}
                onClose={() => setOpen(false)}
                onChange={onDateChange}
                TextFieldComponent={() => null}
            />
        </>
    );
};

export default React.memo(TimeEntryTime);
