import React, { useState } from 'react';
import { IconButton, Menu } from '@material-ui/core';

interface IMenuPopper {
    icon: React.ReactNode;
    buttonDescription: string;
}

const MenuPopper: React.FC<IMenuPopper> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleOpen} aria-label={props.buttonDescription}>
                {props.icon}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {props.children}
            </Menu>
        </div>
    );
};

export default MenuPopper;
