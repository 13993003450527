import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
