import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './InputLabel.styles';

interface IInputLabelProps {
    label: string;
    id?: string;
}

export const InputLabel = (props: IInputLabelProps) => {
    const classes = useStyles();
    return (
        <Typography className={classes.label} component="span" variant="h6" id={props.id}>
            {props.label}
        </Typography>
    );
};
