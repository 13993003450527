import { makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const sharedLabel: CSSProperties = {
    fontWeight: 'bold',
    marginBottom: '0.25rem',
    fontSize: '0.9rem',
    lineHeight: 1,
};

export const sharedInputStyling: CSSProperties = {
    position: 'relative',
    width: '100%',
    padding: '0.25rem 0.5rem',
    border: '2px solid',
    height: '2rem',
};

export const useStyles = makeStyles((theme: Theme) => ({
    input: {
        ...sharedInputStyling,
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[300],
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
    formControl: {
        '& input': {
            ...sharedInputStyling,
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.grey[300],
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
            '&:read-only': {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                paddingLeft: 0,
            },
        },

        '& textarea': {
            ...sharedInputStyling,
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.grey[300],
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    error: {
        '& input': {
            borderColor: theme.palette.error.main,
        },
    },
    icon: {
        marginRight: '0.25rem',
        marginBottom: '0.25rem',
        color: theme.palette.error.dark,
    },
    title: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    inForm: {
        marginBottom: '1rem',
    },
    helpText: {
        marginTop: '0.25rem',
    },
}));
