import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        suggestionsContainerOpen: {
            position: 'absolute',
            zIndex: 1,
            left: 0,
            right: 0,
            width: '100%',
        },
        suggestion: {
            display: 'block',
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
        suggestionListItem: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        description: {
            flexGrow: 1,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '1rem',
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '1rem',
            },
        },
        picker: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '1rem',
            },
            [theme.breakpoints.up('md')]: {
                minWidth: '13rem',
                marginRight: '1rem',
            },
        },
        fullWidth: {
            flexGrow: 1,
        },
    }),
);
