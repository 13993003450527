import { FormControlLabel, Switch } from '@material-ui/core';
import React, { FC, useState, ChangeEvent, useEffect } from 'react';

export const ReplaceExistingEntriesSwitch: FC = () => {
    const [checked, setChecked] = useState<boolean>(
        JSON.parse(localStorage.getItem('clearExisting') ?? 'false') || false,
    );

    const onSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
        localStorage.setItem('clearExisting', JSON.stringify(e.target.checked));
    };

    useEffect(() => {
        localStorage.setItem('clearExisting', JSON.stringify(checked));
    }, [checked]);

    return (
        <FormControlLabel
            label="Clear existing entries"
            control={<Switch checked={checked} onChange={onSwitchChange} color="primary" name="clearexisting" />}
        />
    );
};
