import format from 'date-fns/format';
import React, { useCallback } from 'react';
import { debounce } from 'underscore';
import { Field } from '../Field/Field';

interface IProps {
    label?: string;
    value: Date;
    error?: boolean;
    helperText?: string | null;
    onChange: (date: Date) => void;
}

const Timepicker2: React.FC<IProps> = ({ label, value, error, helperText, onChange }) => {
    const debouncedOnChange = useCallback(debounce(onChange, 500), [onChange]);

    const onTimeChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newTime = new Date(value);
        let splitTime = event.target.value.split(':');
        newTime.setHours(parseInt(splitTime[0]));
        newTime.setMinutes(parseInt(splitTime[1]));
        await debouncedOnChange(newTime);
    };

    return (
        <Field
            label={label}
            defaultValue={format(new Date(value), 'HH:mm')}
            onChange={onTimeChange}
            error={error}
            helperText={helperText ? helperText : undefined}
            helperTextAsTooltip
            type="time"
        />
    );
};

export default Timepicker2;
