export const restoreTitle = () => {
    const element = document.getElementById('t2m-title');
    if (element !== null) {
        element.innerHTML = 'Time2Money';
    }
};

export const updateTitle = (
    timer: string,
    description: string | null,
    project?: string,
    activity?: string,
) => {
    const element = document.getElementById('t2m-title');
    if (element !== null) {
        element.innerHTML = `${timer}${description ? ` - ${description}` : ''}${project ? ` - ${project}` : ''}${
            activity ? ` - ${activity}` : ''
        }`;
    }
};
