import { IConfiguration } from './IConfiguration';
import LOCAL from './environment.local';
import TEST from './environment.test';
import PROD from './environment.prod';

enum Environment {
    LOCAL = 'LOCAL',
    TEST = 'TEST',
    PROD = 'PROD',
}

export const getConfiguration = async (): Promise<IConfiguration> => {
    const env = await getEnvironment();

    if (env === Environment.LOCAL) {
        return LOCAL;
    } else if (env === Environment.TEST) {
        return TEST;
    } else if (env === Environment.PROD) {
        return PROD;
    }

    return LOCAL;
};

const getEnvironment = async () => {
    const result = await fetch('/appConfig.json');
    const json = await result.json();
    return (json.appConfig.environment as string).toUpperCase();
};
