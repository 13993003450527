import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        padding: '0.5rem 0',
        marginBottom: '1rem',
        backgroundColor: theme.palette.secondary.light,
    },
}));
