import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        timerButton: {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
            '&[aria-pressed="true"]': {
                backgroundColor: theme.palette.error.main,
                '&:hover': {
                    backgroundColor: theme.palette.error.light,
                },
            },
        },
        currentTimer: {
            minWidth: '6rem',
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            marginTop: '1.3rem',
        },
    }),
);