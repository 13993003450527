import { makeStyles, Theme } from '@material-ui/core/styles';
import { sharedInputStyling } from '../../Field/Field.styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& input': {
            ...sharedInputStyling,
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.grey[300],
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
            paddingLeft: `3rem`,
            paddingRight: `0.75rem`,
            color: theme.palette.text.primary,
            fontWeight: 400,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '10rem',
        },
    },
    label: {
        marginBottom: '0.25rem',
    },
}));
