import React from 'react';
import { SketchPicker } from 'react-color';
import { Button, Popover } from '@material-ui/core';
import { useStyles } from './Row.styles';

interface IRowProps {
    project: string;
    color: string;
    selectedColor?: (color: string) => string;
}

export const Row = (props: IRowProps) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // TODO: Debounce
    const handleChangeComplete = (color: any) => {
        props.selectedColor && props.selectedColor(color.hex);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.row}>
            <Button
                aria-label={`Change color for ${props.project}`}
                variant="contained"
                onClick={handleClick}
                className={classes.colorPicker}
                style={{ backgroundColor: props.color }}
            />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SketchPicker color={props.color} onChangeComplete={handleChangeComplete} />
            </Popover>
            <span>{props.project}</span>
        </div>
    );
};
