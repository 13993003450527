import { useCallback } from 'react';
import { MsalAuthProvider } from 'react-aad-msal';

interface IApi {
    get: (url: string, q?: any) => Promise<Response>;
    post: (url: string, data: any) => Promise<Response>;
    put: (url: string, data: any) => Promise<Response>;
    deleteRequest: (url: string, q?: any) => Promise<Response>;
}

const buildQueryString = (data: any): string => {
    return Object.keys(data)
        .filter((x) => data[x])
        .map((key: string) => {
            if (data[key] instanceof Array) {
                let param = '';
                let array: any[] = data[key] as any[];
                for (let i = 0; i < array.length; i++) {
                    param += key + '=' + encodeURI(array[i]);
                    if (i < array.length - 1) {
                        param += '&';
                    }
                }
                return param;
            }
            return key + '=' + data[key];
        })
        .join('&');
};

export const useApi = (): IApi => {
    const authProvider = (window['authProvider' as any] as any) as MsalAuthProvider;

    const getToken = useCallback(async () => {
        return (await authProvider.getIdToken()).idToken.rawIdToken;
    }, [authProvider]);

    const get = useCallback(
        async (url: string, q: any = {}) => {
            const queryString = buildQueryString(q);

            return fetch(`${url}${queryString ? `?${queryString}` : ''}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + (await getToken()),
                },
            });
        },
        [getToken],
    );

    const post = useCallback(
        async (url: string, data: any) => {
            return fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + (await getToken()),
                    'Content-Type': 'application/json',
                },
                body: data ? JSON.stringify({ ...data }) : '',
            });
        },
        [getToken],
    );

    const put = useCallback(
        async (url: string, data: any) => {
            return fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: 'Bearer ' + (await getToken()),
                    'Content-Type': 'application/json',
                },
                body: data ? JSON.stringify({ ...data }) : '',
            });
        },
        [getToken],
    );

    const deleteRequest = useCallback(
        async (url: string, q: any = {}) => {
            const queryString = buildQueryString(q);
            return fetch(`${url}${queryString ? `?${queryString}` : ''}`, {
                method: 'DELETE',
                headers: {
                    Authorization: 'Bearer ' + (await getToken()),
                },
            });
        },
        [getToken],
    );

    return {
        get,
        post,
        put,
        deleteRequest,
    };
};
