import { Button, Snackbar } from '@material-ui/core';
import { ExpandLess, ExpandMore, Sync, CheckRounded } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import useSyncApi from '../../../../../Api/SyncApi';
import { useStyles } from './Toolbox.styles';
import { useMilltimeContext } from '../../../../../Providers/MilltimeProvider';
import { IValidatedTimeEntry, ISyncError } from '../../../../../Models/Milltime';

interface IProps {
    from: Date | null;
    to: Date | null;
    allPanelsExpanded: boolean;

    setExpandAllButtonSignal: (signal: number | ((prev: number) => number)) => void;
    setCollapseAllButtonSignal: (signal: number | ((prev: number) => number)) => void;
}

const Toolbox = (props: IProps) => {
    const classes = useStyles();
    const { syncToMilltime } = useSyncApi();
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | undefined>(undefined);
    const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
    const [syncLoading, setSyncLoading] = useState<boolean>(false);
    const [syncSuccess, setSyncSuccess] = useState<boolean>(false);

    const { setSyncErrors, currentTimeEntry } = useMilltimeContext();

    const handleSyncError = () => {
        setAlertSeverity('error');
        setAlertMessage('Could not complete the sync. Please check your time entries.');
        setAlertOpen(true);
    };

    const handleSyncSuccess = () => {
        setSyncSuccess(true);
        setTimeout(() => setSyncSuccess(false), 3000);
    };

    const clearExistingEntries = (): boolean => {
        let clear = localStorage.getItem('clearExisting');
        return clear === null ? false : JSON.parse(clear);
    };

    const sync = async () => {
        if (props.from !== null && props.to !== null) {
            setSyncLoading(true);
            const result = await syncToMilltime(props.from, props.to, clearExistingEntries());
            if (result.status === 400) {
                const body = await result.json();
                const errors: ISyncError[] = body.validationTimeEntries.map(
                    (x: IValidatedTimeEntry): ISyncError => ({
                        id: x.id,
                        errorMessages: x.validationErrorMessage,
                    }),
                );
                setSyncErrors(errors);
                handleSyncError();
            } else if (result.status === 200) {
                setAlertSeverity('success');
                setAlertMessage('Sync to MillTime completed.');
                setAlertOpen(true);
                setSyncErrors([]);
                handleSyncSuccess();
            }
            setSyncLoading(false);
        }
    };

    return (
        <div className={classes.syncButtons}>
            <Button
                endIcon={props.allPanelsExpanded ? <ExpandLess /> : <ExpandMore />}
                variant="outlined"
                size="medium"
                onClick={() =>
                    props.allPanelsExpanded
                        ? props.setCollapseAllButtonSignal((prev) => prev + 1)
                        : props.setExpandAllButtonSignal((prev) => prev + 1)
                }
                className={classes.expandButton}
            >
                {props.allPanelsExpanded ? 'Collapse All' : ' Expand All '}
            </Button>
            <Button
                className={`${classes.syncButton} ${syncSuccess ? classes.syncSuccess : ''}`}
                variant="outlined"
                size="medium"
                onClick={sync}
                disabled={syncLoading || currentTimeEntry !== null}
                endIcon={
                    !syncSuccess ? <Sync className={syncLoading ? classes.spinningSyncIcon : ''} /> : <CheckRounded />
                }
            >
                Sync
            </Button>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert elevation={6} variant="filled" onClose={() => setAlertOpen(false)} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Toolbox;
