import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.grey[200],
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '2ch',
            padding: '0.25rem 0.65rem',
            margin: '0.2rem',
            fontWeight: 500,
        },
    }),
);

const Key: React.FC = (props) => {
    const classes = useStyles();
    return <Typography className={classes.root}>{props.children}</Typography>;
};

export default Key;
