import { IConfiguration } from './IConfiguration';

const config: IConfiguration = {
    azureAdConfig: {
        authority: 'https://login.microsoftonline.com/d89ef75c-38db-4904-9d78-b872502ca145',
        clientId: '3a38e4bd-a25b-4a4b-837a-3aec6049c8d2',
    },
};

export default config;
