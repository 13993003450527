import React from 'react';
import Select, { Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { InputLabel } from '../InputLabel/InputLabel';
import { useStyles } from './Select.styles';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
interface ReactSelectProps extends Props {
    label?: string;
    creatable?: boolean;
    fullWidth?: boolean;
    activity?: boolean;
}

const ReactSelect = (props: ReactSelectProps) => {
    const { label, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();

    const selectStyles = {
        control: (base: any, state: any) => {
            const borderColor = state.isFocused ? theme.palette.primary.main : theme.palette.grey[300];
            const isDisabled = state.isDisabled ? 0.5 : 1;
            return {
                border: `2px solid ${borderColor}`,
                color: theme.palette.grey[900],
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.default,
                display: 'inline-flex',
                cursor: 'text',
                fontSize: '1rem',
                alignItems: 'center',
                width: 'calc(100% - 4px)',
                opacity: isDisabled,
                height: '2rem',
                padding: '0.25rem 0',
            };
        },
        input: () =>
            ({
                font: 'inherit',
                color: 'currentColor',
                margin: 0,
                display: 'block',
                minWidth: '0',
                fontSize: '1rem',
                boxSizing: 'content-box',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                backgroundColor: theme.palette.background.default,
                webkitTapHighlightColor: 'transparent',
            } as React.CSSProperties),
        container: () => ({
            position: 'relative',
        }),
        valueContainer: () => ({
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            webkitOverflowScrolling: 'touch',
            position: 'relative',
            overflow: 'hidden',
            boxSizing: 'border-box',
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
        }),
        dropdownIndicator: () => ({
            cursor: 'pointer',
            color: theme.palette.grey[600],
            display: 'flex',
            alignItems: 'center',
            // -1px to compensate for border
            padding: 'calc(0.5rem - 1px) 0.5rem 0.5rem 0',
        }),
        indicatorSeparator: () => ({}),
        placeholder: () => ({
            position: 'absolute',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            fontSize: '1rem',
            color: theme.palette.grey[600],
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            height: '100%',
            alignSelf: 'normal',
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
                top: '20%',
            },
        }),
        singleValue: () => ({
            position: 'absolute',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            fontSize: '1rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '90%',
            height: '100%',
            alignSelf: 'normal',
            color: theme.palette.text.primary,
            '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
                top: '20%',
            },
        }),
        menu: () => {
            return {
                width: '100%',
                position: 'absolute',
                borderRadius: `0 0 ${theme.shape.borderRadius} ${theme.shape.borderRadius}`,
                zIndex: 10,
                backgroundColor: theme.palette.divider,
                boxShadow: theme.shadows[4],
            };
        },
        multiValueLabel: () => ({
            webkitAlignItems: 'center',
            webkitBoxAlign: 'center',
            msFlexAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            webkitFlexWrap: 'wrap',
            msFlexWap: 'wrap',
            flexWrap: 'wrap',
            padding: '0.25rem 0 0.25rem 0.4rem',
            webkitOverflowScrolling: 'touch',
            position: 'relative',
            overflow: 'hidden',
        }),
        multiValue: () => ({
            backgroundColor: theme.palette.grey[200],
            borderRadius: '1rem',
            display: 'flex',
            marginLeft: '0.5rem',
            minWidth: 0,
        }),
        option: (base: any, state: any) => ({
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            fontSize: '1rem',
            color: theme.palette.text.primary,
            backgroundColor: state.isFocused ? theme.palette.grey[200] : theme.palette.divider,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[100],
            },
        }),
        clearIndicator: () => ({
            cursor: 'pointer',
            marginRight: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.grey[600],
            borderRadius: '50%',
            '&:hover': {
                color: theme.palette.grey[900],
            },
        }),
        multiValueRemove: () => ({
            alignItems: 'center',
            display: 'flex',
            paddingLeft: '0.4rem',
            paddingRight: '0.4rem',
            boxSizing: 'border-box',
            '&:hover': {
                cursor: 'pointer',
                color: theme.palette.error.main,
            },
        }),
    } as any;

    return (
        <div
            style={props.activity ? { minWidth: '13rem' } : undefined}
            className={clsx({ [classes.picker]: true, [classes.fullWidth]: props.fullWidth })}
        >
            {label && <InputLabel label={label} id={label} />}
            {props.creatable ? (
                <CreatableSelect label={label} styles={selectStyles} aria-labelledby={label} {...rest} />
            ) : (
                <Select label={label} styles={selectStyles} aria-labelledby={label} {...rest} />
            )}
        </div>
    );
};

export default ReactSelect;
