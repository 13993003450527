import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    calendar: {
        border: `1px solid ${theme.palette.grey[300]}`,
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.default,
        '& *': {
            color: theme.palette.text.primary,
        },
        '&.react-datepicker': {
            '& .react-datepicker__day--in-range': {
                borderRadius: '0.1rem',
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.primary,
            },
            '& .react-datepicker__day': {
                margin: 0,
                padding: '0.1rem',
                '&:hover': {
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.default,
                },
            },
            '& .react-datepicker__day--selected': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.background.default,
                borderRadius: '50%',
            },
            '& .react-datepicker__day--in-selecting-range': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.primary,
                borderRadius: '0.1rem',
                '&:hover:': {
                    backgroundColor: theme.palette.primary.light,
                },
            },
            '& .react-datepicker__day--range-start': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.background.default,
                borderRadius: '50%',
            },
            '& .react-datepicker__day--range-end': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.background.default,
                borderRadius: '50%',
            },
            '& .react-datepicker__header': {
                backgroundColor: theme.palette.background.default,
                borderBottom: 0,
                paddingTop: '0.5rem',
            },
            '& .react-datepicker__day-names': {
                backgroundColor: theme.palette.grey[100],
                fontWeight: 600,
            },
            '& .react-datepicker__week-number': {
                color: theme.palette.grey[500],
            },
            '& .react-datepicker__day--keyboard-selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.text.primary,
                borderRadius: '50%',
            },
            '& .react-datepicker__triangle': {
                borderBottomColor: theme.palette.background.default,
                '&:before': {
                    borderBottomColor: theme.palette.grey[300],
                },
            },
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0.5rem 0.5rem',
    },
}));
