import React from 'react';
import { useStyles } from './ActionBarContainer.styles';
import { Container } from '../Container/Container';

export interface IActionBarContainerProps {
    children?: React.ReactNode;
}

export const ActionBarContainer = (props: IActionBarContainerProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>{props.children}</Container>
        </div>
    );
};
