import React from 'react';
import { MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { useMilltimeContext } from '../../../../Providers/MilltimeProvider';
import { KeepEntriesGroupedSwitch } from './KeepEntriesGroupedSwitch';
import { ReplaceExistingEntriesSwitch } from './ReplaceExistingEntriesSwitch';
import MenuPopper from '../MenuPopper/MenuPopper';

const UserMenu = () => {
    const { signOut } = useMilltimeContext();

    return (
        <div>
            <MenuPopper icon={<AccountCircle />} buttonDescription="Open menu">
                {/* <MenuItem onClick={() => navigate('/projectcolors')}>Project Colors</MenuItem> */}
                <MenuItem>
                    <ReplaceExistingEntriesSwitch />
                </MenuItem>
                <MenuItem>
                    <KeepEntriesGroupedSwitch />
                </MenuItem>
                <MenuItem onClick={signOut}>Sign Out</MenuItem>
            </MenuPopper>
        </div>
    );
};

export default UserMenu;
