import constate from 'constate';
import { differenceInSeconds, endOfToday, subWeeks } from 'date-fns';
import Cookie from 'js-cookie';
import { useCallback, useEffect, useRef, useState } from 'react';
import useMilltimeApi from '../Api/MilltimeApi';
import useSyncApi from '../Api/SyncApi';
import useTimeEntryApi from '../Api/TimeEntryApi';
import useUserApi from '../Api/UserApi';
import { IDescriptionEntry, IMilltimeProject, ISyncError, ITimeEntry, ITimeEntryTime } from '../Models/Milltime';
import { secondsToElapsedString } from '../Utils/TimeHelpers';
import { restoreTitle, updateTitle } from '../Utils/TitleHelper';

export enum MilltimeAuthResponse {
    Success = 1,
    IncorrectCredentials = 2,
    ServerError = 3,
    UnknownError = 4,
}

const useMilltime = () => {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [initialRequestLoading, setInitialRequestLoading] = useState<boolean>(true);
    const [projects, setProjects] = useState<IMilltimeProject[]>([]);
    const [projectsRequestLoading, setProjectsRequestLoading] = useState<boolean>(false);
    const [descriptionEntries, setDescriptionEntries] = useState<IDescriptionEntry[]>([]);
    const [currentTimeEntry, setCurrentTimeEntry] = useState<ITimeEntryTime | null>(null);
    const [currentTimer, setCurrentTimer] = useState<string>('00:00:00');
    const [syncErrors, setSyncErrors] = useState<ISyncError[]>([]);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [timeEntries, setTimeEntries] = useState<ITimeEntry[]>([]);
    const [loadingTimeEntries, setLoadingTimeEntires] = useState<boolean>(false);
    const [entriesGrouped, setEntriesGrouped] = useState<boolean>(false);

    const { checkCredentials } = useSyncApi();
    const { getAllProjectsAndActivites } = useMilltimeApi();
    const { getDescriptionEntries, getCurrentTimeEntry, getTimeEntries } = useTimeEntryApi();
    const { updateKeepEntriesGrouped, fetchKeepEntriesGrouped } = useUserApi();

    const currentTimerRef = useRef<any>(null);

    const fetchTimeEntries = useCallback(
        async (from: Date, to: Date) => {
            setLoadingTimeEntires(true);
            const result = await getTimeEntries(from, to);
            const data = await result.json();
            setTimeEntries(data);
            setLoadingTimeEntires(false);
        },
        [getTimeEntries],
    );

    const getKeepEntriesGrouped = useCallback(async () => {
        const result = await fetchKeepEntriesGrouped();
        const data = await result.json();
        setEntriesGrouped(data.identicalGroupSelected);
    }, [fetchKeepEntriesGrouped]);

    const setKeepEntriesGrouped = useCallback(
        async (keepGrouped: boolean) => {
            const response = await updateKeepEntriesGrouped(keepGrouped);
            const data = await response.json();
            setEntriesGrouped(data.identicalGroupSelected);
        },
        [updateKeepEntriesGrouped],
    );

    const signIn = useCallback(
        async (username: string, password: string) => {
            const { status } = await checkCredentials(username, password);
            const authenticated = status === 200;
            setAuthenticated(authenticated);

            if (status === 200) {
                return MilltimeAuthResponse.Success;
            } else if (status === 401) {
                return MilltimeAuthResponse.IncorrectCredentials;
            } else if (status === 500) {
                return MilltimeAuthResponse.ServerError;
            } else {
                return MilltimeAuthResponse.UnknownError;
            }
        },
        [checkCredentials],
    );

    const signOut = useCallback(() => {
        Cookie.remove('clientUsername');
        Cookie.remove('clientPassword');
        setAuthenticated(false);
        setProjects([]);
    }, []);

    const checkIfAuthenticated = useCallback(async () => {
        const username = Cookie.get('clientUsername');
        const password = Cookie.get('clientPassword');
        if (username && password) {
            const result = await checkCredentials(username, password);
            if (result.status !== 200) {
                return false;
            }
            return true;
        }
        return false;
    }, [checkCredentials]);

    const usernameAndPasswordSetInCookies = useCallback((): boolean => {
        const username = Cookie.get('clientUsername');
        const password = Cookie.get('clientPassword');
        return username !== undefined && password !== undefined;
    }, []);

    const getProjectsAndActivities = useCallback(
        async (refreshToken: boolean = true) => {
            if (usernameAndPasswordSetInCookies()) {
                setProjects([]);
                setProjectsRequestLoading(true);

                try {
                    const result = await getAllProjectsAndActivites(refreshToken);
                    const projects = await result.json();
                    setProjects(projects);
                } catch {
                    // TODO (daol): Something went wrong with fetching the projects
                } finally {
                    setProjectsRequestLoading(false);
                }
            }
        },
        [usernameAndPasswordSetInCookies, getAllProjectsAndActivites],
    );

    const getAllDescriptionEntries = useCallback(async () => {
        const from = subWeeks(new Date(), 3);
        const to = endOfToday();
        const result = await getDescriptionEntries(from, to);
        const data = await result.json();
        setDescriptionEntries(data);
    }, [getDescriptionEntries]);

    const getCurrentEntry = useCallback(async () => {
        const result = await getCurrentTimeEntry();
        if (result.status === 200) {
            const timeEntry: ITimeEntryTime = await result?.json();
            setCurrentTimeEntry(timeEntry);
        }
    }, [getCurrentTimeEntry]);

    const init = useCallback(async () => {
        const authenticated = await checkIfAuthenticated();
        setAuthenticated(authenticated);
        setInitialRequestLoading(false);
    }, [checkIfAuthenticated]);

    useEffect(() => {
        init();
    }, [init]);

    // Current timer handling...
    useEffect(() => {
        const calculateTimerText = () => {
            if (currentTimeEntry !== null) {
                const seconds = differenceInSeconds(new Date(), new Date(currentTimeEntry.startTime));
                const elapsedTime = secondsToElapsedString(seconds);
                setCurrentTimer(elapsedTime);
                const project = projects.find((x) => x.id === currentTimeEntry?.projectId);
                const activity = project?.activities.find((x) => x.id === currentTimeEntry?.activityId);
                updateTitle(elapsedTime, currentTimeEntry.description, project?.name, activity?.name);
            }
        };

        if (currentTimeEntry !== null) {
            currentTimerRef.current = setInterval(() => {
                calculateTimerText();
            }, 500);
        } else {
            clearInterval(currentTimerRef.current);
            currentTimerRef.current = null;
            setCurrentTimer('00:00:00');
            restoreTitle();
        }
        return () => {
            clearInterval(currentTimerRef.current);
            currentTimerRef.current = null;
        };
    }, [currentTimeEntry, projects]);

    useEffect(() => {
        const toggleFavIcon = () => {
            let icon = document.getElementById('favicon');
            icon?.setAttribute('href', currentTimeEntry !== null ? '/faviconttm.ico' : '/favicon.ico');
        };

        toggleFavIcon();
    }, [currentTimeEntry]);

    useEffect(() => {
        const sendInitialRequests = async () => {
            if (authenticated) {
                await Promise.all([
                    getProjectsAndActivities(),
                    getAllDescriptionEntries(),
                    getCurrentEntry(),
                    getKeepEntriesGrouped(),
                ]);
                setInitialLoading(false);
            }
        };

        sendInitialRequests();
    }, [authenticated, getProjectsAndActivities, getAllDescriptionEntries, getCurrentEntry, getKeepEntriesGrouped]);

    return {
        authenticated,
        initialRequestLoading,
        projects,
        projectsRequestLoading,
        descriptionEntries,
        currentTimeEntry,
        currentTimer,
        syncErrors,
        initialLoading,
        timeEntries,
        loadingTimeEntries,
        entriesGrouped,
        signIn,
        signOut,
        getProjectsAndActivities,
        setSyncErrors,
        setCurrentTimeEntry,
        fetchTimeEntries,
        getAllDescriptionEntries,
        setKeepEntriesGrouped,
    };
};

const [MilltimeProvider, useMilltimeContext] = constate(useMilltime);
export { MilltimeProvider, useMilltimeContext };
