import { Grid, Typography } from '@material-ui/core';
import { compareDesc, differenceInSeconds, isThisWeek, isToday } from 'date-fns';
import React, { FC, useCallback, useEffect, useState } from 'react';
import _, { Dictionary } from 'underscore';
import useUserApi from '../../../../Api/UserApi';
import { ITimeEntry, ITimeEntryTime } from '../../../../Models/Milltime';
import { IProjectColors } from '../../../../Models/User';
import { useMilltimeContext } from '../../../../Providers/MilltimeProvider';
import { minutesToTotalHoursAndMinutes, secondsToElapsedString } from '../../../../Utils/TimeHelpers';
import { useExpandedHook } from '../ExpandedHook';
import { useStyles } from './TimeEntires.style';
import { TimeEntryExpansionPanel } from './TimeEntryExpansionPanel/TimeEntryExpansionPanel';
import TimeEntryTime from './TimeEntryExpansionPanel/TimeEntryTime/TimeEntryTime';

interface IProps {
    from: Date | null;
    to: Date | null;
    expandAllButtonSignal: number;
    collapseAllButtonSignal: number;
    setAllPanelsExpanded: (allExpanded: boolean) => void;
}

// TODO (daol):
// When starting an entry from an already existing entry, 3 "getEntries" requests are sent.
// There are currently too many useEffect fetching entries. Reduce!

const getPanelUniqueId = (timeEntry: ITimeEntry) => {
    const id = timeEntry.times[0].id;
    return `puid-${id}`;
};

const TimeEntries = ({ from, to, expandAllButtonSignal, setAllPanelsExpanded, collapseAllButtonSignal }: IProps) => {
    const [projectColors, setProjectColors] = useState<IProjectColors[]>([]);
    const [expandedPanels, setExpandedPanels] = useState<string[]>([]);
    const { currentTimeEntry, setSyncErrors, fetchTimeEntries, timeEntries, entriesGrouped } = useMilltimeContext();
    const { getProjectColors } = useUserApi();
    const classes = useStyles();

    const {
        allPanelsAreExpanded,
        increaseNumberOfExpandedEntries,
        decreaseNumberOfExpandedEntries,
    } = useExpandedHook();

    const fetchEntries = useCallback(async () => {
        if (from !== null && to !== null) {
            await fetchTimeEntries(from, to);
            setSyncErrors([]);
        }
    }, [from, to, fetchTimeEntries, setSyncErrors]);

    useEffect(() => {
        fetchEntries();
    }, [fetchEntries, currentTimeEntry, entriesGrouped]);

    useEffect(() => {
        const fetchProjectColors = async () => {
            const result = await getProjectColors();
            const data = await result.json();
            setProjectColors(data);
        };

        fetchProjectColors();
    }, [getProjectColors]);

    useEffect(() => {
        setAllPanelsExpanded(allPanelsAreExpanded);
    }, [allPanelsAreExpanded, setAllPanelsExpanded]);

    const getTimeEntriesGrouped = (): Dictionary<ITimeEntry[]> => {
        return _.groupBy(timeEntries, (x) => x.timeEntryDate);
    };

    const getGroupTimer = (group: ITimeEntry[], isToday: boolean) => {
        if (!isToday) {
            const totalMinuntes = group.reduce((a, b) => a + b.totalMinutes, 0);
            return minutesToTotalHoursAndMinutes(totalMinuntes);
        } else {
            let totalSeconds = group.reduce((a, b) => a + b.totalSeconds, 0);
            if (currentTimeEntry !== null) {
                totalSeconds += differenceInSeconds(new Date(), new Date(currentTimeEntry.startTime));
            }
            return secondsToElapsedString(totalSeconds);
        }
    };

    const groupSorter = (dateA: string, dateB: string) => compareDesc(new Date(dateA), new Date(dateB));

    const groupedTimeEntries = getTimeEntriesGrouped();

    const getWeekTime = () => {
        const keys = Object.keys(groupedTimeEntries).filter((x) => isThisWeek(new Date(x), { weekStartsOn: 1 }));

        let totalSeconds = 0;
        keys.forEach((x) => (totalSeconds = groupedTimeEntries[x].reduce((a, b) => a + b.totalSeconds, totalSeconds)));
        if (currentTimeEntry !== null) {
            totalSeconds += differenceInSeconds(new Date(), new Date(currentTimeEntry.startTime));
        }

        return secondsToElapsedString(totalSeconds);
    };

    const dateSorter = (entryA: ITimeEntryTime, entryB: ITimeEntryTime) =>
        new Date(entryA.startTime).getTime() - new Date(entryB.startTime).getTime();

    const getAllTimesFromGroup = (timeEntryGroup: ITimeEntry[]): ITimeEntryTime[] => {
        let times: ITimeEntryTime[] = [];
        timeEntryGroup.forEach((group) => {
            times = times.concat(group.times);
        });

        return times.sort(dateSorter);
    };

    return (
        <div>
            {timeEntries.length > 0 ? (
                <>
                    {[...Object.keys(groupedTimeEntries)].sort(groupSorter).map((timeEntryGroup, index) => (
                        <div key={`grp-${timeEntryGroup}`} className={classes.timerOuterContainer}>
                            <div className={classes.timerContainer}>
                                <Typography variant="h4" className={classes.title}>
                                    <span className={classes.bold}>{new Date(timeEntryGroup).toDateString()}:</span>{' '}
                                    {getGroupTimer(
                                        groupedTimeEntries[timeEntryGroup],
                                        isToday(new Date(timeEntryGroup)),
                                    )}
                                </Typography>
                                {index === 0 && (
                                    <Typography variant="h4" className={classes.title}>
                                        <span className={classes.bold}>This week: </span>
                                        {getWeekTime()}
                                    </Typography>
                                )}
                            </div>
                            <div>
                                {entriesGrouped ? (
                                    <>
                                        {[...groupedTimeEntries[timeEntryGroup]].reverse().map((timeEntry) => (
                                            <TimeEntryExpansionPanel
                                                key={getPanelUniqueId(timeEntry)}
                                                timeEntry={timeEntry}
                                                projectColors={projectColors}
                                                expandedPanels={expandedPanels}
                                                setExpandedPanels={setExpandedPanels}
                                                fetchEntries={fetchEntries}
                                                expandAllButtonSignal={expandAllButtonSignal}
                                                collapseAllButtonSignal={collapseAllButtonSignal}
                                                allPanelsExpanded={allPanelsAreExpanded}
                                                increaseNumberOfExpandedEntries={increaseNumberOfExpandedEntries}
                                                decreaseNumberOfExpandedEntries={decreaseNumberOfExpandedEntries}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {[...getAllTimesFromGroup(groupedTimeEntries[timeEntryGroup])]
                                            .reverse()
                                            .map((time) => (
                                                <TimeEntryTime
                                                    key={`tet-${time.id}`}
                                                    hideLabels
                                                    time={time}
                                                    fetchTimeEntries={fetchEntries}
                                                    displayStartFromEntryButton
                                                />
                                            ))}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <GetToWork />
            )}
        </div>
    );
};

const GetToWork: FC = () => {
    const classes = useStyles();

    return (
        <Grid container justify="center" style={{ marginTop: '8rem' }}>
            <Grid container item xs={12} justify="center">
                <Grid item xs={4}>
                    <Typography className={classes.getToWorkHeader} align="center" variant="h2">
                        "Get to work"
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="center">
                <Grid item xs={2}>
                    <Typography className={classes.getToWorkSubtitle} align="right" variant="h4">
                        - Majoren
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TimeEntries;
