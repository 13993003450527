import { FormControlLabel, Switch } from '@material-ui/core';
import React, { ChangeEvent, FC, useState } from 'react';
import { useMilltimeContext } from '../../../../Providers/MilltimeProvider';

export const KeepEntriesGroupedSwitch: FC = () => {
    const { entriesGrouped, setKeepEntriesGrouped } = useMilltimeContext();
    const [loading, setLoading] = useState<boolean>(false);

    const onSwitchChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        await setKeepEntriesGrouped(!entriesGrouped);
        setLoading(false);
    };

    return (
        <FormControlLabel
            label="Keep entries grouped"
            control={
                <Switch
                    checked={entriesGrouped}
                    onChange={onSwitchChange}
                    color="primary"
                    name="keepentriesgrouped"
                    disabled={loading}
                />
            }
        />
    );
};
