import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: '0.5rem',
        },
        subtitle: {
            marginBottom: '1.5rem',
        },
        formContainer: {
            padding: '3rem 1.5rem 0',
        },
        loginForm: {
            display: 'flex',
            flexDirection: 'column',
        },
        loginButton: {
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }),
);
