import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    row: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem 0 1rem',
        marginBottom: '0.5rem',
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    colorPicker: {
        marginRight: '1rem',
        minWidth: 0,
        height: '2rem',
        width: '2rem',
        border: `1px solid ${theme.palette.grey[200]}`,
    },
}));
