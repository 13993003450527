import { makeStyles, Theme } from '@material-ui/core/styles';
import { sharedLabel } from '../../../../../Shared/Field/Field.styles';

export const useStyles = makeStyles((theme: Theme) => ({
    time: {
        marginRight: '0.5rem',
        maxWidth: '8rem',
        '& > label': {
            fontWeight: theme.typography.h6.fontWeight,
            ...sharedLabel,
            color: theme.palette.grey[800],
        },
    },
    timeSummaryContainer: {
        maxWidth: '4rem',
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '1rem',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'stretch',
        },
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > button': {
            padding: '10px',
        },
    },
    groupedButtonContainer: {
        marginTop: '1rem',
    },
    timepicker: {
        marginRight: '1rem',
    },
    timeInputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    notGroupedMargin: {
        marginTop: '1rem',
    },
}));
