import {
    Chip,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core';
import { WarningSharp } from '@material-ui/icons';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ISyncError, ITimeEntry } from '../../../../../Models/Milltime';
import { IProjectColors } from '../../../../../Models/User';
import { useMilltimeContext } from '../../../../../Providers/MilltimeProvider';
import { StartFromPreviousEntry } from '../StartFromPreviousEntry';
import { useStyles } from './TimeEntryExpansionPanel.styles';
import TimeEntryTime from './TimeEntryTime/TimeEntryTime';

interface IProps {
    timeEntry: ITimeEntry;
    projectColors: IProjectColors[];
    expandedPanels: string[];
    expandAllButtonSignal: number;
    collapseAllButtonSignal: number;
    allPanelsExpanded: boolean;
    increaseNumberOfExpandedEntries: () => void;
    decreaseNumberOfExpandedEntries: () => void;
    setExpandedPanels: (expandedPanels: string[] | ((prev: string[]) => string[])) => void;
    fetchEntries: () => Promise<void>;
}

const getPanelUniqueId = (timeEntry: ITimeEntry) => {
    return `${timeEntry.timeEntryDate}-${timeEntry.projectId}-${timeEntry.activityId}-${timeEntry.description}`;
};

export const TimeEntryExpansionPanel: FC<IProps> = ({
    timeEntry,
    projectColors,
    expandAllButtonSignal,
    collapseAllButtonSignal,
    fetchEntries,
    increaseNumberOfExpandedEntries,
    decreaseNumberOfExpandedEntries,
}) => {
    const { projects, syncErrors } = useMilltimeContext();

    const [expanded, setExpanded] = useState<boolean>(false);

    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {
        setExpanded(true);
    }, [expandAllButtonSignal]);

    useEffect(() => {
        setExpanded(false);
    }, [collapseAllButtonSignal]);

    useEffect(() => {
        expanded ? increaseNumberOfExpandedEntries() : decreaseNumberOfExpandedEntries();
    }, [expanded, increaseNumberOfExpandedEntries, decreaseNumberOfExpandedEntries]);

    const getProjectName = (projectId: string | null) => {
        return projects.find((x) => x.id === projectId)?.name;
    };

    const getActivityName = (projectId: string | null, activityId: string | null) => {
        return projects.find((x) => x.id === projectId)?.activities.find((x) => x.id === activityId)?.name;
    };

    const getProjetColor = (projectId: string | null, hasError: boolean) => {
        const color = hasError ? theme.palette.error.main : projectColors.find((x) => x.projectId === projectId)?.color;

        return color ? { borderColor: color } : {};
    };

    const getGroupTime = (minutes: number): string => {
        const hours = Math.floor(minutes / 60);
        const mintes = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mintes.toString().padStart(2, '0')}`;
    };

    const hasInvalidEntry = (entry: ITimeEntry, errors: ISyncError[]): boolean => {
        const syncError = errors.find((x) => entry.times.some((y) => y.id === x.id));
        return syncError?.errorMessages ? syncError.errorMessages.length > 0 : false;
    };

    const getErrors = (entry: ITimeEntry, errors: ISyncError[]): string[] => {
        const entryIds = entry.times.map((x) => x.id);
        const errorsForEntry = errors.find((x) => entryIds.some((y) => y === x.id))?.errorMessages;

        return errorsForEntry ? errorsForEntry : [];
    };

    const times = useMemo(() => {
        return [...timeEntry.times].reverse();
    }, [timeEntry]);

    return (
        <>
            <ExpansionPanel
                key={getPanelUniqueId(timeEntry)}
                className={classes.timeEntry}
                style={getProjetColor(timeEntry.projectId, hasInvalidEntry(timeEntry, syncErrors))}
                expanded={expanded}
                onChange={() => setExpanded((e: boolean) => !e)}
            >
                <ExpansionPanelSummary className={classes.summary}>
                    <div className={classes.summaryInfoContainer}>
                        {hasInvalidEntry(timeEntry, syncErrors) && (
                            <Tooltip
                                title={
                                    <div>
                                        {getErrors(timeEntry, syncErrors).map((x, i) => (
                                            <div key={`warning-${i}`}>{x}</div>
                                        ))}
                                    </div>
                                }
                            >
                                <WarningSharp className={classes.warningTriangle} />
                            </Tooltip>
                        )}
                        {timeEntry && timeEntry.times && timeEntry.times.length > 1 && (
                            <Chip className={classes.numberOfTimesChip} label={timeEntry.times.length} />
                        )}
                        <Typography>{timeEntry.description ? timeEntry.description : '-'}</Typography>
                    </div>
                    <div className={classes.summaryInfoContainer}>
                        <Typography className={classes.bold}>
                            {getProjectName(timeEntry.projectId)}{' '}
                            {getActivityName(timeEntry.projectId, timeEntry.activityId)}
                        </Typography>
                        <Typography className={classes.timeEntryTotalTime}>
                            {getGroupTime(timeEntry.totalMinutes)}
                        </Typography>
                        <StartFromPreviousEntry timeEntry={timeEntry} fetchEntries={fetchEntries} />
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    {times.map((time) => (
                        <TimeEntryTime
                            key={`${getPanelUniqueId(timeEntry)}-${time.id}`}
                            time={time}
                            fetchTimeEntries={fetchEntries}
                        />
                    ))}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </>
    );
};
