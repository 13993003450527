export const secondsToElapsedString = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / (60 * 60));
    const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60);
    const seconds = totalSeconds - hours * 60 * 60 - minutes * 60;
    
    const elapsedHours = `0${hours}`.slice(-2);
    const elapsedMinutes = `0${minutes}`.slice(-2);
    const elapsedSeconds = `0${seconds}`.slice(-2);
    return `${elapsedHours}:${elapsedMinutes}:${elapsedSeconds}`;
}

export const secondsToElapsedHoursAndMinutes = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / (60 * 60));
    const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60);
    
    const elapsedHours = `0${hours}`.slice(-2);
    const elapsedMinutes = `0${minutes}`.slice(-2);
    return `${elapsedHours}:${elapsedMinutes}`;
}

export const minutesToTotalHoursAndMinutes = (totalMinutes: number): string => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes - hours * 60;

    const elapsedHours = `0${hours}`.slice(-2);
    const elapsedMinutes = `0${minutes}`.slice(-2);
    return `${elapsedHours}:${elapsedMinutes}`;
}