import { Button, Typography } from '@material-ui/core';
import { PlayArrow, Stop } from '@material-ui/icons';
import React, { FC } from 'react';
import { useMilltimeContext } from '../../../../../Providers/MilltimeProvider';
import Timepicker2 from '../../../../Shared/Timepicker/Timepicker2';
import { useStyles } from './Timer.styles';
import { useHotkeys } from 'react-hotkeys-hook';
interface IProps {
    timerActive: boolean;
    activeEntryStartTime: Date | null;
    startStopRequestLoading: boolean;

    startNewEntry: () => void;
    stopEntry: () => void;
    updateActiveEntryStartTime: (startTime: Date) => void;
}

const Timer: FC<IProps> = (props: IProps) => {
    const classes = useStyles();
    const { currentTimeEntry, currentTimer } = useMilltimeContext();
    const { timerActive } = props;

    const onTimerClick = () => {
        if (props.startStopRequestLoading) {
            return;
        }
        timerActive ? props.stopEntry() : props.startNewEntry();
    };

    useHotkeys(
        'ctrl+enter,ctrl+s',
        (event: KeyboardEvent) => {
            event.preventDefault();
            onTimerClick();
        },
        [timerActive],
    );

    return (
        <div className={classes.root}>
            {/* {props.activeEntryStartTime !== null && (
                <Timepicker
                    margin="none"
                    label="Start time"
                    value={props.activeEntryStartTime}
                    onChange={props.updateActiveEntryStartTime}
                    ampm={false}
                />
            )} */}
            {props.activeEntryStartTime !== null && (
                <Timepicker2
                    label="Start"
                    onChange={props.updateActiveEntryStartTime}
                    value={props.activeEntryStartTime}
                />
            )}
            {currentTimeEntry !== null && (
                <div className={classes.currentTimer}>
                    <Typography>{currentTimer}</Typography>
                </div>
            )}
            <Button
                className={classes.timerButton}
                onClick={onTimerClick}
                variant="contained"
                aria-label="Start timer"
                aria-pressed={timerActive}
                disabled={props.startStopRequestLoading}
            >
                {timerActive ? <Stop /> : <PlayArrow />}
            </Button>
        </div>
    );
};

export default Timer;
