import React from 'react';
import { useStyles } from './Logotype.styles';

const Logotype = () => {
    const classes = useStyles();

    return (
        <div>
            <svg version="1.1" id="Component_4_1" x="0px" y="0px" viewBox="0 0 161 161.2" className={classes.root}>
                <style type="text/css"></style>
                <g>
                    <g id="Ellipse_3_1_">
                        <path
                            className={classes.st0}
                            d="M80.6,78.2L80.1,0C34.9,0,0,36,0,80.4c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.2c0,21.2,8.3,40.5,21.8,54.9"
                        />
                    </g>
                    <path
                        className={classes.st1}
                        d="M80.6,78.2l-58.9,57.5c14.7,14.6,35.8,25.5,58.3,25.5c44.9,0,81.2-36.1,81.2-80.6S124.8,0,80,0L80.6,78.2z"
                    />
                </g>
                <g className={classes.st2}>
                    <path
                        className={classes.st3}
                        d="M101.7,108.5c-3.9,3.8-8.9,6.3-15,7.4v8.9c0,1.5-0.5,2.8-1.5,3.8c-1,1-2.3,1.5-3.8,1.5c-1.5,0-2.7-0.5-3.7-1.5
		s-1.5-2.3-1.5-3.8v-8.7c-4.3-0.4-8.1-1.3-11.6-2.8c-3.4-1.5-6.7-3.7-9.9-6.6c-1.5-1.3-2.3-2.8-2.3-4.5c0-1.3,0.5-2.5,1.5-3.6
		c1-1.1,2.2-1.6,3.6-1.6c1.2,0,2.3,0.4,3.2,1.2c2.3,2.2,4.8,3.9,7.3,5.2c2.5,1.2,5.2,2.1,8.1,2.5V80.6C69.2,79,63.8,76.4,60,73
		c-3.7-3.5-5.6-8.2-5.6-14.2c0-5.8,2-10.6,6-14.4c4-3.8,9.3-6.1,15.8-6.9v-5.7c0-1.5,0.5-2.8,1.5-3.8c1-1,2.3-1.5,3.8-1.5
		c1.5,0,2.7,0.5,3.6,1.5c1,1,1.4,2.3,1.4,3.8v5.8c3.4,0.5,6.6,1.4,9.6,2.8c2.9,1.3,5.4,2.9,7.4,4.8c1.7,1.5,2.5,3,2.5,4.7
		c0,1.3-0.5,2.5-1.5,3.5c-1,1-2.2,1.5-3.6,1.5c-1,0-1.9-0.3-2.5-0.9c-1.3-1.3-3-2.5-5.2-3.6S89,48.6,86.7,48v24.4
		c7,1.8,12.3,4.4,15.7,7.6c3.4,3.3,5.2,8.1,5.2,14.5C107.6,100.1,105.6,104.7,101.7,108.5z M67.8,65.4c1.9,1.8,4.7,3.3,8.4,4.5V47.6
		c-3.5,0.6-6.3,1.8-8.3,3.7c-2,1.9-3,4.2-3,6.9C64.9,61.2,65.8,63.6,67.8,65.4z M94.1,101.5c1.9-1.8,2.9-4,2.9-6.5
		c-0.1-2.8-1-5.1-2.7-7c-1.7-1.9-4.3-3.4-7.6-4.7v22.1C89.7,104.7,92.2,103.3,94.1,101.5z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default Logotype;
