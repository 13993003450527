import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType, MsalAuthProvider } from 'react-aad-msal';
import { IAzureAdConfig } from '../Config/IConfiguration';

export const getAuthProvider = (adConfig: IAzureAdConfig): MsalAuthProvider => {
    const config: Configuration = {
        auth: {
            authority: adConfig.authority,
            clientId: adConfig.clientId,
            redirectUri: `${window.location.protocol}//${window.location.host}`,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
        },
    };
    const authenticationParameters: AuthenticationParameters = {
        scopes: ['user.read'],
    };

    const options: IMsalAuthProviderConfig = {
        loginType: LoginType.Redirect,
        tokenRefreshUri: `${window.location.protocol}//${window.location.host}/callback.html`,
    };

    const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
    (window as any)['authProvider'] = authProvider;
    return authProvider;
};
