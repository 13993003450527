import { createMuiTheme } from '@material-ui/core/styles';
import { dark } from './dark';
import { light } from './light';

// Using the same theme as the interface of your os
const getPalette = window.matchMedia('(prefers-color-scheme: dark)').matches ? dark : light;

export const theme = createMuiTheme({
    palette: getPalette,
    overrides: {
        MuiExpansionPanel: {
            root: {
                boxShadow: 'none',
                border: `1px solid ${getPalette.grey[300]}`,
                borderRadius: '5px',
                backgroundColor: getPalette.background.default,
                '&.Mui-expanded:last-child': {
                    marginBottom: 'inherit !important',
                },
                '&:before': {
                    height: 0,
                },
            },
            rounded: {
                borderRadius: 5,
            },
        },
        MuiExpansionPanelSummary: {
            root: {
                paddingRight: 0,
                '&.Mui-expanded': {
                    minHeight: 0,
                },
            },
            content: {
                '&.Mui-expanded': {
                    margin: 0,
                },
            },
        },
        MuiExpansionPanelDetails: {
            root: {
                padding: '1.5rem',
            },
        },
        MuiMenuItem: {
            root: {
                paddingTop: '0.75rem',
                paddingBottom: '0.75rem',
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: getPalette.divider,
                // Since autocomplete still is in "Lab", you can't override it.
                '& .MuiAutocomplete-option': {
                    '&:hover': {
                        backgroundColor: getPalette.grey[100],
                    },
                },
            },
        },
        MuiInputLabel: {
            formControl: {
                position: 'initial',
                transform: 'unset',
                top: 'unset',
                left: 'unset',
            },
            shrink: {
                transform: 'unset',
                transformOrigin: 'unset',
            },
        },
        MuiInputBase: {
            input: {
                height: '2rem',
                color: getPalette.text.primary,
                '&::-webkit-calendar-picker-indicator': {
                    filter: getPalette.background.default === '#151515' ? 'invert(100%)' : 'white',
                },
            },
        },
        MuiSwitch: {
            track: {
                backgroundColor: getPalette.background.paper,
            },
        },
        MuiDialogContent: {
            root: {
                // Since MuiPickersCalendar still is in "Lab", you can't override it.
                '& .MuiPickersCalendarHeader-iconButton': {
                    backgroundColor: 'transparent',
                },
                '& .MuiPickersCalendarHeader-dayLabel': {
                    color: getPalette.text.primary,
                },
            },
        },
        MuiButton: {
            root: {
                textTransform: 'none',
                borderRadius: 5,
                minHeight: '2.75rem',
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    borderColor: getPalette.primary.dark,
                },
                '&:active': {
                    boxShadow: 'none',
                },
                '&:disabled': {
                    color: getPalette.grey[500],
                    borderColor: getPalette.grey[300],
                },
            },
            outlined: {
                padding: '0.5rem 1rem',
                border: `2px solid ${getPalette.grey[300]}`,
                '&:disabled': {
                    color: getPalette.grey[500],
                    border: `2px solid ${getPalette.grey[300]}`,
                },
            },
            outlinedPrimary: {
                border: `2px solid ${getPalette.primary.main}`,
                '&:hover': {
                    border: `2px solid ${getPalette.primary.main}`,
                },
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'auto',
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: 5,
                color: getPalette.grey[600],
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.75rem',
                backgroundColor: getPalette.divider,
                color: getPalette.text.primary,
                boxShadow:
                    '0px 2px 1px -1px rgba(0,0,0,0.15), 0px 1px 1px 0px rgba(0,0,0,0.10), 0px 1px 3px 0px rgba(0,0,0,0.09)',
            },
        },
        MuiTab: {
            root: {
                flexGrow: 1,
                maxWidth: 'none',
            },
        },
        MuiTabs: {
            root: {
                color: getPalette.text.primary,
            },
        },
        MuiPopover: {
            paper: {
                backgroundColor: getPalette.divider,
            },
        },
        MuiInputAdornment: {
            positionEnd: {
                right: '1rem',
                position: 'absolute',
                '& > *': {
                    fontWeight: 'bold',
                    color: getPalette.text.primary,
                },
            },
            positionStart: {
                left: '1rem',
                position: 'absolute',
                zIndex: 1,
                '& > *': {
                    fontWeight: 'bold',
                    color: getPalette.text.primary,
                },
            },
        },
        MuiTypography: {
            root: {
                color: getPalette.text.primary,
            },
            h1: {
                marginTop: '1rem',
                fontSize: '1.5rem',
                fontWeight: 700,
            },
            h2: {
                fontSize: '1.4rem',
            },
            h3: {
                fontSize: '1.3rem',
            },
            h4: {
                fontSize: '1.1rem',
            },
            h5: {
                fontSize: '1rem',
                fontWeight: 'bold',
                color: getPalette.grey[700],
            },
            h6: {
                fontSize: '0.9rem',
            },
            subtitle1: {
                fontWeight: 'bold',
            },
            overline: {
                fontSize: '0.9rem',
                fontWeight: 'bold',
                color: 'rgba(0, 0, 0, 0.6)',
            },
        },
    },
    shape: {
        borderRadius: 5,
    },
});
