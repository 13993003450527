import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
        },
        title: {
            marginTop: '1.5rem',
        },
        timeEntriesContainer: {
            backgroundColor: theme.palette.secondary.dark,
        },
    }),
);
