import { createStyles, Grow, Grid, IconButton, makeStyles, Paper, Popper, Theme, Typography } from '@material-ui/core';
import { KeyboardRounded } from '@material-ui/icons';
import Key from './Key/Key';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popper: {
            minWidth: '24rem',
        },
        content: {
            padding: theme.spacing(2),
        },
        divider: {
            padding: '0 0.5rem',
            color: theme.palette.grey[600],
        },
        title: {
            fontWeight: 600,
            marginBottom: '0.5rem',
        },
        list: {
            padding: 0,
            margin: 0,
        },
    }),
);

const KeyboardShortcuts = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const classes = useStyles();

    const toggleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (Boolean(anchorEl)) {
            handleClose();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Popper
                className={classes.popper}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom"
                transition
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} timeout={350}>
                        <Paper aria-live="polite" className={classes.content}>
                            <Typography id="keyboard-shortcut-title" className={classes.title} variant="h4">
                                Keyboard shortcuts
                            </Typography>
                            <Grid
                                container
                                className={classes.list}
                                aria-labelledby="keyboard-shortcut-title"
                                direction="column"
                                component="ul"
                            >
                                <Grid item container alignItems="center" justify="space-between" component="li">
                                    <Grid item>
                                        <Typography>Start/Stop</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <Key>ctrl</Key>
                                            <Key>s</Key>
                                            <Typography className={classes.divider}> / </Typography>
                                            <Key>ctrl</Key>
                                            <Key>enter</Key>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <Grid container justify="center">
                <Grid item>
                    <IconButton
                        onBlur={handleClose}
                        aria-label="Open description for keyboard shortcuts"
                        onClick={toggleOpen}
                    >
                        <KeyboardRounded />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default KeyboardShortcuts;
