import { IconButton } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import React, { FC } from 'react';
import useTimeEntryApi from '../../../../Api/TimeEntryApi';
import { INewTimeEntry, ITimeEntry, ITimeEntryTime } from '../../../../Models/Milltime';
import { useMilltimeContext } from '../../../../Providers/MilltimeProvider';

interface IProps {
    timeEntry?: ITimeEntry;
    time?: ITimeEntryTime;
    fetchEntries: () => Promise<void>;
}

export const StartFromPreviousEntry: FC<IProps> = ({ timeEntry, time, fetchEntries }) => {
    const { currentTimeEntry, setCurrentTimeEntry } = useMilltimeContext();
    const { stopEntry, startNewEntry } = useTimeEntryApi();

    const startFromPreviousEntry = async (event: any, timeEntry: ITimeEntry | ITimeEntryTime) => {
        event.stopPropagation();

        if (currentTimeEntry !== null) {
            await stopEntry(currentTimeEntry.id, new Date());
        }

        const entryData: INewTimeEntry = {
            id: null,
            projectId: timeEntry.projectId,
            activityId: timeEntry.activityId,
            description: timeEntry.description,
            startTime: new Date(),
            stopTime: null,
        };

        const result = await (await startNewEntry(entryData)).json();
        setCurrentTimeEntry(null);
        setCurrentTimeEntry(result);
        await fetchEntries();
    };

    const timeToUse = timeEntry ?? time;

    if (!timeToUse) {
        return null;
    }

    return (
        <IconButton
            onClick={(event: any) => startFromPreviousEntry(event, timeToUse)}
            aria-label="Start from previous entry"
        >
            <PlayArrow />
        </IconButton>
    );
};
