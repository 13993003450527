import React from 'react';
import './App.css';
import AuthenticatedContainer from './Components/Authenticated/Authenticated';
import MilltimeLogin from './Components/Milltime/MilltimeLogin';
import { useMilltimeContext } from './Providers/MilltimeProvider';
import { LinearProgress } from '@material-ui/core';
import { useStyles } from './App.styles';

const App = () => {
    const milltimeContext = useMilltimeContext();
    const classes = useStyles();

    const isAuthenticated = (): boolean => {
        return milltimeContext.authenticated;
    };

    if (milltimeContext.initialRequestLoading) {
        return <LinearProgress />;
    }

    return <div className={classes.root}>{isAuthenticated() ? <AuthenticatedContainer /> : <MilltimeLogin />}</div>;
};

export default App;
