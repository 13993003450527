export const light = {
    primary: {
        light: '#5E58FF',
        main: '#2B24E2',
        dark: '#1F1A9D',
        contrastText: '#fff',
    },
    secondary: {
        light: '#FDFCFF',
        main: '#DE00AB',
        dark: '#7B005E',
        contrastText: '#fff',
    },
    grey: {
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
    },
    background: {
        default: '#ffffff',
        paper: '#000',
    },
    text: {
        primary: '#000000',
    },
    error: {
        main: '#DC3030',
        light: '#E36463',
    },
    divider: '#fff',
};
