import React, { forwardRef } from 'react';
import { InputBaseProps, FormControl, InputBase, InputAdornment, Typography, IconButton } from '@material-ui/core';
import DateIcon from '@material-ui/icons/DateRangeRounded';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { useStyles } from './DateInput.styles';

interface IProps extends InputBaseProps {
    label?: string;
    clear?: () => void;
}

const DateInput = (props: IProps, ref: any) => {
    const classes = useStyles();
    const { label, clear, ...rest } = props;

    return (
        <FormControl className={classes.root}>
            {props.label && (
                <Typography component="label" variant="h4" className={classes.label}>
                    {label}
                </Typography>
            )}
            <InputBase
                ref={ref}
                {...rest}
                startAdornment={
                    <InputAdornment position="start">
                        <DateIcon />
                    </InputAdornment>
                }
                endAdornment={
                    props.clear && (
                        <InputAdornment position="end">
                            <IconButton size="small" aria-label="Clear field">
                                <ClearIcon fontSize="small" onClick={clear} />
                            </IconButton>
                        </InputAdornment>
                    )
                }
            />
        </FormControl>
    );
};

export default forwardRef(DateInput);
