import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    picker: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '18rem',
            marginRight: '1rem',
        },
    },
    fullWidth: {
        flexGrow: 1,
    },
}));
