import DateFnsUtils from '@date-io/date-fns';
import { AppBar, Tab, Tabs, Typography, useMediaQuery, useTheme, CircularProgress, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RouteComponentProps } from '@reach/router';
import { endOfWeek, startOfWeek } from 'date-fns';
import React, { useState } from 'react';
import { ActionBarContainer } from '../../Shared/ActionBarContainer/ActionBarContainer';
import { Container } from '../../Shared/Container/Container';
import SyncBar from './SyncBar/SyncBar';
import TimeEntries from './TimeEntires/TimeEntries';
import TimerBar from './TimerBar/TimerBar';
import { useStyles } from './TimeTracker.styles';
import { useMilltimeContext } from '../../../Providers/MilltimeProvider';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div style={{ paddingTop: '1.5rem' }}>{children}</div>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TimeTracker = (props: RouteComponentProps) => {
    const [expandAllButtonSignal, setExpandAllButtonSignal] = useState<number>(0);
    const [collapseAllButtonSignal, setCollapseAllButtonSignal] = useState<number>(0);
    const [allPanelsExpanded, setAllPanelsExpanded] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<Date | null>(startOfWeek(new Date(), { weekStartsOn: 1 }));
    const [toDate, setToDate] = useState<Date | null>(endOfWeek(new Date(), { weekStartsOn: 1 }));
    const { initialLoading } = useMilltimeContext();
    // Tabs
    const [value, setValue] = useState(0);

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const classes = useStyles();

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {mobile ? (
                    <>
                        <AppBar position="static" color="transparent" elevation={0} className={classes.appBar}>
                            <Tabs value={value} onChange={handleChange} aria-label="Switch between timer and sync">
                                <Tab label="Timer" {...a11yProps(0)} />
                                <Tab label="Sync" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <Container>
                                <TimerBar />
                                <TimeEntries
                                    from={fromDate}
                                    to={toDate}
                                    expandAllButtonSignal={expandAllButtonSignal}
                                    collapseAllButtonSignal={collapseAllButtonSignal}
                                    setAllPanelsExpanded={setAllPanelsExpanded}
                                />
                            </Container>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Container>
                                <SyncBar
                                    fromDate={fromDate}
                                    setFromDate={setFromDate}
                                    toDate={toDate}
                                    setToDate={setToDate}
                                    setExpandAllButtonSignal={setExpandAllButtonSignal}
                                    setCollapseAllButtonSignal={setCollapseAllButtonSignal}
                                    allPanelsExpanded={allPanelsExpanded}
                                />
                            </Container>
                        </TabPanel>
                    </>
                ) : (
                    <>
                        <ActionBarContainer>
                            <SyncBar
                                fromDate={fromDate}
                                setFromDate={setFromDate}
                                toDate={toDate}
                                setToDate={setToDate}
                                setExpandAllButtonSignal={setExpandAllButtonSignal}
                                setCollapseAllButtonSignal={setCollapseAllButtonSignal}
                                allPanelsExpanded={allPanelsExpanded}
                            />
                        </ActionBarContainer>
                        <Container>
                            {initialLoading && (
                                <Grid container item xs={12} justify="center">
                                    <CircularProgress />
                                </Grid>
                            )}
                            {!initialLoading && (
                                <>
                                    <Typography className={classes.title} variant="h1">
                                        Timer
                                    </Typography>
                                    <TimerBar />
                                    <TimeEntries
                                        from={fromDate}
                                        to={toDate}
                                        expandAllButtonSignal={expandAllButtonSignal}
                                        collapseAllButtonSignal={collapseAllButtonSignal}
                                        setAllPanelsExpanded={setAllPanelsExpanded}
                                    />
                                </>
                            )}
                        </Container>
                    </>
                )}
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default TimeTracker;
