import React from 'react';
import DatePickers from './DatePickers/DatePickers';
import Toolbox from './Toolbox/Toolbox';
import { useStyles } from './SyncBar.styles';

interface IProps {
    fromDate: Date | null;
    toDate: Date | null;
    allPanelsExpanded: boolean;

    setExpandAllButtonSignal: (signal: number | ((prev: number) => number)) => void;
    setCollapseAllButtonSignal: (signal: number | ((prev: number) => number)) => void;
    setFromDate: (date: Date | null) => void;
    setToDate: (date: Date | null) => void;
}

const SyncBar = (props: IProps) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <DatePickers
                fromDate={props.fromDate}
                setFromDate={props.setFromDate}
                toDate={props.toDate}
                setToDate={props.setToDate}
            />
            <Toolbox
                from={props.fromDate}
                to={props.toDate}
                setExpandAllButtonSignal={props.setExpandAllButtonSignal}
                setCollapseAllButtonSignal={props.setCollapseAllButtonSignal}
                allPanelsExpanded={props.allPanelsExpanded}
            />
        </div>
    );
};

export default SyncBar;
