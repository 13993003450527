import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { AzureAD } from 'react-aad-msal';
import ReactDOM from 'react-dom';
import App from './App';
import { getConfiguration } from './Config/EnvironmentConfiguration';
import './index.css';
import { getAuthProvider } from './Providers/AuthProvider';
import { MilltimeProvider } from './Providers/MilltimeProvider';
import * as serviceWorker from './serviceWorker';
import { theme } from './Theme/theme';

(async () => {
    const config = await getConfiguration();
    const authProvider = getAuthProvider(config.azureAdConfig);

    ReactDOM.render(
        <AzureAD provider={authProvider} forceLogin>
            <MuiThemeProvider theme={theme}>
                <MilltimeProvider>
                    <App />
                </MilltimeProvider>
            </MuiThemeProvider>
        </AzureAD>,
        document.getElementById('root'),
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
})();
