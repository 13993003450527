import { TextField, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IMilltimeActivity, IMilltimeProject } from '../../../../Models/Milltime';
import { useMilltimeContext } from '../../../../Providers/MilltimeProvider';
import { InputLabel } from '../../../Shared/InputLabel/InputLabel';
import ReactSelect from '../../../Shared/Select/Select';
import { useStyles } from './TaskPicker.styles';

interface IProps {
    project: IMilltimeProject | null;
    activity: IMilltimeActivity | null;
    description: string | null;
    hideLabels?: boolean;

    setProject: (project: IMilltimeProject | null) => void;
    setActivity: (activity: IMilltimeActivity | null) => void;
    setDescription: (description: string | null) => void;
    onSuggestionSelected: (
        project: IMilltimeProject | null,
        activity: IMilltimeActivity | null,
        description: string | null,
    ) => void;
}

interface ILabeledEntity {
    value: string;
    label: string;
}

const CustomAutocomplete = withStyles((theme) => ({
    inputRoot: {
        '&&[class*="MuiOutlinedInput-root"]': {
            color: theme.palette.text.primary,
            border: `2px solid ${theme.palette.grey[300]}`,
            height: '2.75rem',
            padding: 0,
            '&:focus-within': {
                borderColor: theme.palette.primary.main,
            },
            '& > fieldset': {
                display: 'none',
            },
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
            padding: '0.25rem 0.5rem',
        },
    },
}))(Autocomplete);

const TaskPicker: FC<IProps> = ({
    project,
    activity,
    description,
    hideLabels,
    setProject,
    setActivity,
    setDescription,
    onSuggestionSelected,
}) => {
    const { projects, descriptionEntries } = useMilltimeContext();
    const [activities, setActivities] = useState<IMilltimeActivity[]>([]);
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const projectOptions: ILabeledEntity[] = useMemo(
        () => projects.map((x) => ({ value: x.id, label: x.name })),
        [projects],
    );
    const activityOptions: ILabeledEntity[] = useMemo(
        () => activities.map((x) => ({ value: x.id, label: x.name })),
        [activities],
    );

    useEffect(() => {
        setActivities(project?.activities || []);
    }, [project]);

    const onProjectChanged = useCallback(
        (selected: any) => {
            const project = projects.find((x) => x.id === selected?.value);
            setProject(project || null);
            setActivities(project?.activities || []);
        },
        [projects, setProject],
    );

    const onActivityChanged = useCallback(
        (selected: any) => {
            const activity = activities.find((x) => x.id === selected?.value);
            setActivity(activity || null);
        },
        [activities, setActivity],
    );

    const OptionsComponent = (optionProps: any) => {
        const getProjectAndActivityText = (projectId: string | null, activityId: string | null) => {
            const project = projects.find((x) => x.id === projectId)?.name;
            if (!project) return '-';

            const activity = activities.find((x) => x.id === activityId)?.name;
            return !activity ? project : `${project} - ${activity}`;
        };

        return (
            <>
                <div>
                    <div>
                        <Typography variant="body1">{optionProps.label}</Typography>
                    </div>
                    <div>
                        <Typography variant="body2">
                            {getProjectAndActivityText(optionProps.value.projectId, optionProps.value.activityId)}
                        </Typography>
                    </div>
                </div>
            </>
        );
    };

    const getDescriptionEntries = useCallback(() => {
        return descriptionEntries.map((x) => ({
            value: x,
            label: x.description ?? '-',
        })) as any;
    }, [descriptionEntries]);

    const onDescriptionSelected = (event: any, option: any) => {
        if (option === null) {
            setDescription(null);
        } else if (typeof option === 'string') {
            setDescription(option);
        } else {
            const project = projects.find((x) => x.id === option.value.projectId);
            const activity = project?.activities.find((x) => x.id === option.value.activityId);
            onSuggestionSelected(project ?? null, activity ?? null, option.value.description);
        }
    };

    const onInputBlur = () => {
        if (!inputRef.current) {
            return;
        }

        let value = inputRef.current.value === '' ? null : inputRef.current.value;

        if (value !== description) {
            setDescription(value);
        }
    };

    return (
        <div className={classes.root}>
            <div className={`${classes.picker} ${classes.fullWidth}`}>
                {!hideLabels && <InputLabel aria-hidden="true" label="Description" id="description" />}
                <TooltipWrapper title={description}>
                    <CustomAutocomplete
                        fullWidth
                        freeSolo
                        options={getDescriptionEntries()}
                        getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.label)}
                        value={description}
                        renderInput={(params) => (
                            <TextField
                                inputRef={inputRef}
                                label={<span className="sr-only">Description</span>}
                                {...params}
                                margin="none"
                                variant="outlined"
                                onBlur={onInputBlur}
                            />
                        )}
                        renderOption={OptionsComponent}
                        onChange={onDescriptionSelected}
                    />
                </TooltipWrapper>
            </div>
            <TooltipWrapper title={project?.name ?? null}>
                <MemoedSelect
                    options={projectOptions}
                    label={hideLabels ? undefined : 'Project'}
                    onChange={onProjectChanged}
                    value={project ? { label: project.name, value: project.id } : null}
                />
            </TooltipWrapper>

            <TooltipWrapper title={activity?.name ?? null}>
                <MemoedSelect
                    options={activityOptions}
                    label={hideLabels ? undefined : 'Activity'}
                    onChange={onActivityChanged}
                    value={activity ? { label: activity.name, value: activity.id } : null}
                    activity
                />
            </TooltipWrapper>
        </div>
    );
};

interface IMemoedSelectProps {
    options: ILabeledEntity[];
    label: string | undefined;
    onChange: (selected: any) => void;
    value: ILabeledEntity | null;
    activity?: boolean;
}

const MemoedSelect: FC<IMemoedSelectProps> = memo((props) => {
    return (
        <ReactSelect
            isClearable
            isSearchable
            placeholder=""
            label={props.label}
            options={props.options}
            onChange={props.onChange}
            value={props.value}
            activity={props.activity}
        />
    );
});

const TooltipWrapper: FC<{ title: string | null }> = ({ title, children }) => {
    return title ? (
        <Tooltip title={title} placement="top">
            <div>{children}</div>
        </Tooltip>
    ) : (
        <>{children}</>
    );
};

export default TaskPicker;
