import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: '100%',
        width: '1340px',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            margin: '0 1rem',
            width: 'initial',
        },
    },
}));
