import { useApi } from './Api';
import { useCallback } from 'react';
import { format } from 'date-fns';

interface ISyncApi {
    checkCredentials: (username: string, password: string) => Promise<Response>;
    getAllProjectsAndActivites: (refreshCache: boolean) => Promise<Response>;
    syncToMilltime: (from: Date, to: Date, clearExistingEntries?: boolean) => Promise<Response>;
}

const useSyncApi = (): ISyncApi => {
    const { get, post } = useApi();

    const checkCredentials = useCallback(
        (username: string, password: string) => {
            return post('/api/sync/credentials', { ClientUsername: username, ClientPassword: password });
        },
        [post],
    );

    const getAllProjectsAndActivites = useCallback(
        (refreshCache: boolean) => {
            return get('/api/milltime/tasks', { refresh: refreshCache });
        },
        [get],
    );

    const syncToMilltime = useCallback(
        (from: Date, to: Date, clearExistingEntries: boolean = false) => {
            return post('/api/sync', {
                syncFrom: format(from, 'yyyy-MM-dd HH:mm:ss'),
                syncTo: format(to, 'yyyy-MM-dd HH:mm:ss'),
                clearExistingEntries: clearExistingEntries,
            });
        },
        [post],
    );

    return {
        checkCredentials,
        getAllProjectsAndActivites,
        syncToMilltime,
    };
};

export default useSyncApi;
