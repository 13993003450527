import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'flex-end',
            margin: '1rem 0 1.5rem',
        },
    }),
);
