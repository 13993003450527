import { useApi } from './Api';
import { useCallback } from 'react';

interface IUserApi {
    getProjectColors: () => Promise<Response>;
    updateKeepEntriesGrouped: (keepGrouped: boolean) => Promise<Response>;
    fetchKeepEntriesGrouped: () => Promise<Response>;
}

const useUserApi = (): IUserApi => {
    const { get, post } = useApi();

    const getProjectColors = useCallback(() => {
        return get('/api/user/project-colors');
    }, [get]);

    const updateKeepEntriesGrouped = useCallback(
        (keepGrouped: boolean) => {
            return post('/api/user/identicalgroup', { identicalGroupSelected: keepGrouped });
        },
        [post],
    );

    const fetchKeepEntriesGrouped = useCallback(() => {
        return get('/api/user/identicalgroup');
    }, [get]);

    return { getProjectColors, updateKeepEntriesGrouped, fetchKeepEntriesGrouped };
};

export default useUserApi;
