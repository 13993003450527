import { setSeconds } from 'date-fns';
import React, { useState, useEffect } from 'react';
import useTimeEntryApi from '../../../../Api/TimeEntryApi';
import { IMilltimeActivity, IMilltimeProject, INewTimeEntry } from '../../../../Models/Milltime';
import { useMilltimeContext } from '../../../../Providers/MilltimeProvider';
import TaskPicker from '../TaskPicker/TaskPicker';
import Timer from './Timer/Timer';
import { useStyles } from './TimerBar.styles';

const TimerBar = () => {
    const [description, setDescription] = useState<string | null>('');
    const [project, setProject] = useState<IMilltimeProject | null>(null);
    const [activity, setActivity] = useState<IMilltimeActivity | null>(null);
    const [startStopRequestLoading, setStartStopRequestLoading] = useState<boolean>(false);
    const { currentTimeEntry, setCurrentTimeEntry, projects, getAllDescriptionEntries } = useMilltimeContext();
    const { startNewEntry, stopEntry, updateTimeEntry } = useTimeEntryApi();
    const classes = useStyles();

    useEffect(() => {
        if (currentTimeEntry !== null) {
            const project = projects.find((x) => x.id === currentTimeEntry.projectId);
            const activity = project?.activities.find((x) => x.id === currentTimeEntry.activityId);
            setProject(project ?? null);
            setActivity(activity ?? null);
            setDescription(currentTimeEntry.description);
        }
    }, [currentTimeEntry, projects]);

    const onTimerStart = async () => {
        const entryData: INewTimeEntry = {
            id: null,
            projectId: project?.id || null,
            activityId: activity?.id || null,
            description: description || null,
            startTime: new Date(),
            stopTime: null,
        };

        setStartStopRequestLoading(true);
        const result = await (await startNewEntry(entryData)).json();
        setStartStopRequestLoading(false);
        setCurrentTimeEntry(result);
    };

    const onTimerStop = async () => {
        if (currentTimeEntry !== null) {
            setStartStopRequestLoading(true);
            await stopEntry(currentTimeEntry.id, new Date());
            setStartStopRequestLoading(false);
            setCurrentTimeEntry(null);
            setProject(null);
            setActivity(null);
            setDescription(null);
            await getAllDescriptionEntries();
        }
    };

    const updateEntry = async (newData: Partial<INewTimeEntry>) => {
        if (currentTimeEntry !== null) {
            const updatedData: INewTimeEntry = {
                id: currentTimeEntry.id,
                projectId: currentTimeEntry.projectId,
                activityId: currentTimeEntry.activityId,
                description: currentTimeEntry.description,
                startTime: new Date(currentTimeEntry.startTime),
                stopTime: currentTimeEntry.stopTime ? new Date(currentTimeEntry.stopTime) : null,
            };

            Object.assign(updatedData, newData);
            const result = await (await updateTimeEntry(updatedData.id as number, updatedData)).json();
            setCurrentTimeEntry(result);
        }
    };

    const updateProject = async (newProject: IMilltimeProject | null) => {
        setProject(newProject);
        setActivity(null);
        await updateEntry({ projectId: newProject?.id, activityId: null });
    };

    const updateActivity = async (newActivity: IMilltimeActivity | null) => {
        setActivity(newActivity);
        await updateEntry({ activityId: newActivity?.id });
    };

    const updateDescription = async (newDescription: string | null) => {
        setDescription(newDescription);
        await updateEntry({ description: newDescription });
    };

    const updateStartTime = async (startTime: Date) => {
        await updateEntry({ startTime: setSeconds(startTime, 0) });
    };

    const onSuggestionSelected = async (
        newProject: IMilltimeProject | null,
        newActivity: IMilltimeActivity | null,
        newDescription: string | null,
    ) => {
        setProject(newProject);
        setActivity(newActivity);
        setDescription(newDescription);
        await updateEntry({ projectId: newProject?.id, activityId: newActivity?.id, description: newDescription });
    };

    return (
        <section className={classes.root}>
            <TaskPicker
                project={project}
                activity={activity}
                description={description}
                setProject={updateProject}
                setActivity={updateActivity}
                setDescription={updateDescription}
                onSuggestionSelected={onSuggestionSelected}
            />
            <Timer
                startNewEntry={onTimerStart}
                timerActive={currentTimeEntry !== null}
                stopEntry={onTimerStop}
                activeEntryStartTime={currentTimeEntry?.startTime ?? null}
                updateActiveEntryStartTime={updateStartTime}
                startStopRequestLoading={startStopRequestLoading}
            />
        </section>
    );
};

export default TimerBar;
