import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '2.5rem',
    },
    st0: {
        fill: theme.palette.secondary.main,
    },
    st1: {
        fill: theme.palette.primary.main,
    },
    st2: {
        enableBackground: 'new',
    },
    st3: {
        fill: '#FFFFFF',
    },
}));
