import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        timerOuterContainer: {
            paddingBottom: '1rem',
        },
        timerContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        title: {
            marginBottom: '0.5rem',
        },
        thisWeek: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        bold: {
            fontWeight: 'bold',
        },
        getToWorkHeader: {
            fontSize: '3rem',
            color: '#8e8e8e61',
            fontStyle: 'italic',
            userSelect: 'none',
        },
        getToWorkSubtitle: {
            color: '#8e8e8e61',
            fontStyle: 'italic',
            userSelect: 'none',
        },
    }),
);
