import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    timeEntry: {
        borderLeft: '5px solid',
        marginBottom: '1rem',
    },
    summary: {
        '& > div': {
            display: 'flex',
            justifyContent: 'space-between',
            margin: 0,
        },
    },
    summaryInfoContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    warningTriangle: {
        color: theme.palette.error.main,
        marginRight: '0.5rem',
    },
    numberOfTimesChip: {
        marginRight: '0.5rem',
    },
    bold: {
        fontWeight: 'bold',
    },
    timeEntryTotalTime: {
        marginLeft: '0.5rem',
    },
    details: {
        display: 'block',
    },
}));
