import React from 'react';
import { Grid, AppBar, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { Container } from '../../Shared/Container/Container';
import KeyboardShortcuts from './KeyboardShortcuts/KeyboardShortcuts';
import Logotype from './Logotype/Logotype';
import UserMenu from './UserMenu/UserMenu';
import { useStyles } from './Header.styles';

const Header = () => {
    const theme = useTheme();
    const classes = useStyles();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="static" color="transparent" className={classes.root} elevation={0}>
            <Container>
                <Toolbar className={classes.toolbar} disableGutters>
                    <Logotype />
                    <Grid container justify="flex-end">
                        {!mobile && <KeyboardShortcuts />}
                        {/* Todo: add when we can get theme from backend */}
                        {/* <ThemeMenu /> */}
                        <UserMenu />
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
