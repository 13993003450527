import { useApi } from './Api';
import { useCallback } from 'react';
import { INewTimeEntry } from '../Models/Milltime';
import { format } from 'date-fns';

interface ITimeEntryApi {
    getTimeEntries: (from: Date, to: Date) => Promise<Response>;
    getDescriptionEntries: (from: Date, to: Date) => Promise<Response>;
    startNewEntry: (entryData: INewTimeEntry) => Promise<Response>;
    stopEntry: (entryId: number, stopTime: Date) => Promise<Response>;
    getCurrentTimeEntry: () => Promise<Response>;
    updateTimeEntry: (id: number, timeEntry: INewTimeEntry) => Promise<Response>;
    deleteTimeEntry: (id: number) => Promise<Response>;
}

const useTimeEntryApi = (): ITimeEntryApi => {
    const { get, post, put, deleteRequest } = useApi();

    const getTimeEntries = useCallback(
        (from: Date, to: Date) => {
            return get('/api/timeentry', {
                startDate: format(from, 'yyyy-MM-dd HH:mm:ss'),
                stopDate: format(to, 'yyyy-MM-dd HH:mm:ss'),
            });
        },
        [get],
    );

    const getDescriptionEntries = useCallback(
        (from: Date, to: Date) => {
            return get('/api/timeentry/descriptionentry', {
                startDate: from.toISOString(),
                stopDate: to.toISOString(),
            });
        },
        [get],
    );

    const startNewEntry = useCallback(
        (entryData: INewTimeEntry) => {
            return post('/api/timeentry', {
                ...entryData,
                startTime: format(entryData.startTime, 'yyyy-MM-dd HH:mm:ss'),
            });
        },
        [post],
    );

    const stopEntry = useCallback(
        (entryId: number, stopTime: Date) => {
            return put(`/api/timeentry/${entryId}/stoptime`, { stopTime: format(stopTime, 'yyyy-MM-dd HH:mm:ss') });
        },
        [put],
    );

    const getCurrentTimeEntry = useCallback(() => {
        return get('/api/timeentry/current');
    }, [get]);

    const updateTimeEntry = useCallback(
        (id: number, timeEntry: INewTimeEntry) => {
            return put(`/api/timeentry/${id}`, {
                ...timeEntry,
                startTime: format(timeEntry.startTime, 'yyyy-MM-dd HH:mm:ss'),
                stopTime: timeEntry.stopTime !== null ? format(timeEntry.stopTime, 'yyyy-MM-dd HH:mm:ss') : null,
            });
        },
        [put],
    );

    const deleteTimeEntry = useCallback(
        (id: number) => {
            return deleteRequest(`/api/timeentry/${id}`);
        },
        [deleteRequest],
    );

    return {
        getTimeEntries,
        getDescriptionEntries,
        startNewEntry,
        stopEntry,
        getCurrentTimeEntry,
        updateTimeEntry,
        deleteTimeEntry,
    };
};

export default useTimeEntryApi;
