import { useState, useCallback, useMemo } from 'react';

export const useExpandedHook = () => {
    const [numberOfTimeEntries, setNumberOfTimeEntries] = useState<number>(0);
    const [numberOfExpandedEntries, setNumberOfExpandedEntries] = useState<number>(0);

    const allPanelsAreExpanded = useMemo(() => {
        return numberOfTimeEntries === numberOfExpandedEntries;
    }, [numberOfTimeEntries, numberOfExpandedEntries]);

    const increaseNumberOfExpandedEntries = useCallback(() => {
        setNumberOfExpandedEntries((x) => x + 1);
    }, []);

    const decreaseNumberOfExpandedEntries = useCallback(() => {
        setNumberOfExpandedEntries((x) => x - 1);
    }, []);

    return {
        allPanelsAreExpanded,
        setNumberOfTimeEntries,
        increaseNumberOfExpandedEntries,
        setNumberOfExpandedEntries,
        decreaseNumberOfExpandedEntries
    };
};
