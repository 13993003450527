import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        syncButtons: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                width: '100%',
            },
        },
        syncButton: {
            [theme.breakpoints.up('md')]: {
                marginLeft: '1rem',
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '1rem',
                width: '100%',
            },
        },
        spinningSyncIcon: {
            animation: '$rotate 1.5s linear infinite',
        },
        syncSuccess: {
            backgroundColor: theme.palette.success.main,
        },
        '@keyframes rotate': {
            to: {
                transform: 'rotate(360deg)',
            },
        },
        expandButton: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }),
);
