import { useApi } from './Api';
import { useCallback } from 'react';

interface IMilltimeApi {
    getAllProjectsAndActivites: (refreshCache: boolean) => Promise<Response>;
}

const useMilltimeApi = (): IMilltimeApi => {
    const { get } = useApi();

    const getAllProjectsAndActivites = useCallback((refreshCache: boolean) => {
        return get('/api/milltime/tasks', { refresh: refreshCache });
    }, [get]);

    return {
        getAllProjectsAndActivites,
    };
};

export default useMilltimeApi;
