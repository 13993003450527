import { LocationProvider, Router } from '@reach/router';
import React from 'react';
import Header from './Header/Header';
import ColorPicker from './ProjectColors/ProjectColors';
import TimeTracker from './TimeTracker/TimeTracker';

const AuthenticatedContainer = () => {
    return (
        <div>
            <LocationProvider>
                <Header />
                <main>
                    <Router>
                        <TimeTracker path="/" />
                        <ColorPicker path="projectcolors" />
                    </Router>
                </main>
            </LocationProvider>
        </div>
    );
};

export default AuthenticatedContainer;
